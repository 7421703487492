import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../css/contact.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faXTwitter, faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLocation, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <div>
      <Navbar/>
        <h1 style={{color: 'navy', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}} data-aos="fade-up" data-aos-delay="500">Contact Us</h1>
        <hr />
        <div className='getintouchdiv'>
          <div className='getintouch' data-aos="fade-up" data-aos-delay="500">
            <h1 style={{marginTop: '5%'}} data-aos="fade-up" data-aos-delay="500">Get In Touch</h1>
            <Link to='https://www.facebook.com/DigiTelGlobe'><FontAwesomeIcon className='cfacebook' icon={faFacebook}></FontAwesomeIcon></Link>
            <Link to='https://twitter.com/digitelglobe'><FontAwesomeIcon className='ctwitter' icon={faXTwitter}></FontAwesomeIcon></Link>
            <Link to='https://www.youtube.com/@digitelglobe'><FontAwesomeIcon className='cyoutube' icon={faYoutube}></FontAwesomeIcon></Link>
            <Link to='https://www.linkedin.com/company/digitelglobe/'><FontAwesomeIcon className='clinkedin' icon={faLinkedin}></FontAwesomeIcon></Link>
            <Link to='https://www.instagram.com/digitelglobe/?hl=en'><FontAwesomeIcon className='cinstagram' icon={faInstagram}></FontAwesomeIcon></Link>
          </div>
        </div> 

        <div className='addressdiv' data-aos="fade-up" data-aos-delay="500">
          <FontAwesomeIcon className='falocation' icon={faLocation}></FontAwesomeIcon>
          <h1 style={{color: 'navy', marginTop: '1%', fontFamily: 'sans-serif'}}>Our Locations</h1>
          <p style={{fontFamily: 'sans-serif', marginTop: '1%', fontSize: 'large'}}><b style={{color: 'navy'}}>Lahore:</b> Office 316, Floor F1 Jeff Heights Gulberg III, Lahore</p>
          <p style={{fontFamily: 'sans-serif', marginTop: '1%', fontSize: 'large'}}><b style={{color: 'navy'}}>Islamabad:</b> Office 711, Floor 7, Zara Heights Sector H-13, Islamabad</p>
          <p style={{fontFamily: 'sans-serif', marginTop: '1%', fontSize: 'large'}}><b style={{color: 'navy'}}>Karachi:</b> Suite 19, Mezzanine Floor, Sahil Pomenade, Block 3, Clifton, Karachi</p>
          <p style={{fontFamily: 'sans-serif', marginTop: '1%', fontSize: 'large'}}><b style={{color: 'navy'}}>Gilgit:</b> Office#307, ZS Plaza, Opp. Radio Pakistan, Shahrah-e-Quaid-e-Azam, Jutial Cantt, Gilgit</p>
        </div>

        <div className='cediv' data-aos="fade-up" data-aos-delay="500">
          <div className='cuo' data-aos="fade-up" data-aos-delay="500">
            <FontAwesomeIcon style={{fontSize: 'xx-large', marginTop: '2%', color: 'navy'}} icon={faPhone}></FontAwesomeIcon>
            <h1 style={{color: 'navy', marginTop: '2%', fontFamily: 'sans-serif'}}>Call Us On</h1>
            <p style={{fontFamily: 'sans-serif', fontSize: 'large', marginTop: '2%'}}>+92 335 333 1140</p>
            <p style={{fontFamily: 'sans-serif', fontSize: 'large', marginTop: '2%'}}>+92 335 333 1145</p>
          </div>
          <div className='eu' data-aos="fade-up" data-aos-delay="500">
          <FontAwesomeIcon style={{fontSize: 'xx-large', marginTop: '2%', color: 'navy'}} icon={faEnvelope}></FontAwesomeIcon>
            <h1 style={{color: 'navy', marginTop: '2%', fontFamily: 'sans-serif'}}>Email Us</h1>
            <p style={{fontFamily: 'sans-serif', fontSize: 'large', marginTop: '2%'}}>info@DigiTel.com.pk</p>
          </div>
        </div>
        <h1 style={{color: 'navy', textAlign: 'center', marginTop: '5%', font: 'sans-serif'}} data-aos="fade-up" data-aos-delay="500">Send Us A Message</h1>
        <ContactForm data-aos="fade-up" data-aos-delay="500"/>
      <Footer/>
    </div>
  )
}

export default Contact