import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Navbar.css'; // Ensure this file has appropriate styles
import logo from '../Components/Images/logoblue.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons'; // X icon for Twitter

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img className='logo' src={logo} alt="digitel-logo" />
        </NavLink>
        <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-controls="navbarNav" aria-expanded={isMenuOpen} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" activeClassName="active">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/About" activeClassName="active">About Us</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/BusinessUnits" activeClassName="active">Specialization</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/OurPartners" activeClassName="active">Our Partners</NavLink>
            </li> 
            <li className="nav-item">
              <NavLink className="nav-link" to="/Products" activeClassName="active">FlexyRax</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/Contact" activeClassName="active">Contact</NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.facebook.com/DigiTelGlobe" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className='facebook' icon={faFacebook} />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://twitter.com/digitelglobe" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className='twitter' icon={faX} /> {/* X logo for Twitter */}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.instagram.com/digitelglobe/?hl=en" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className='instagram' icon={faInstagram} />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.youtube.com/@digitelglobe" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className='youtube' icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
