import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../css/FlexyRax.css'
import ac1 from '../Components/Images/FlexyRax/AestheticalyCrafted1.jpeg'
import ac2 from '../Components/Images/FlexyRax/AestheticallyCrafted2.jpeg'
import basic from '../Components/Images/FlexyRax/Basic.jpeg'
import range1 from '../Components/Images/FlexyRax/Range1.jpeg'
import range2 from '../Components/Images/FlexyRax/Range2.jpeg'
import specs from '../Components/Images/FlexyRax/Specification.jpeg'
import ct from '../Components/Images/FlexyRax/CableTrays.jpeg'
import bc from '../Components/Images/FlexyRax/BatteryCabinet.jpeg'

const Products = () => {
  return (
    <div>
        <Navbar/>
            <h1 style={{color: 'darkblue', textAlign: 'center', marginTop: '3%', fontFamily: 'sans-serif'}}>Introducing DIGITEL DATACENTER CABINETS</h1>
            <hr  style={{color: 'darkblue'}}/>
            <h1 style={{color: 'darkblue', textAlign: 'center', marginTop: '2%', fontFamily: 'sans-serif'}}>FlexyRax 1.0</h1>
            <hr  style={{color: 'darkblue'}}/>
            <div style={{backgroundColor: 'darkblue', color: 'white', textAlign: 'center', fontFamily: 'sans-serif'}}>
                <h1>AESTHETICALLY CRAFTED</h1>
                <div className='acparent'>
                <div className='acimg'><img className='acimg1' src={ac1} alt="ac1" /></div>
                <div className='acimg'><img className='acimg2' src={ac2} alt="ac2" /></div>
            </div>
            </div>


            <div style={{backgroundColor: 'darkblue', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', marginTop: '0.2%'}}>
                <h1>FLEXYRAX 1.0 BASIC</h1>
                <div className='basic'>
                    <div className='basic10'>
                        <img className='basicimg' src={basic} alt="basic" />
                    </div>
                    <div className='basic10text'><h5 className='basich5'>The FlexyRax 1.0 Basic variant is a durable and versatile solution for modern data centers. Its modular design allows flexible configurations, ensuring optimal airflow and cooling for different rack sizes.</h5><h5 style={{
                        lineHeight: '200%'
                    }}>With user-friendly cable management and easy installation, it streamlines operations, making it suitable for both new setups and upgrades in IT infrasturcture.</h5></div>
                </div>
            </div>

            <div style={{backgroundColor: 'darkblue', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', marginTop: '0.2%'}}>
                <h1>PRODUCT RANGE</h1>
                <div className='range'>
                     <div className='rangep'>
                        <img className='range1p' src={range1} alt="range1" />
                     </div>
                     <div className='rangetext'>
                        <h5>The FlexyRax 1.0 Basic variant is available in a comprehensive range of sizes, from 6U to 42U racks, catering to diverse storage and equipment needs. Each model can be further customized to meet specific requirements, ensuring optimal integraion into any data center environment. This flexibility allows businesses to tailor their setups, making the FlexyRax 1.0 an ideal solution for both small-scale operations and large enterprise deployments</h5>
                     </div>
                     <div className='rangep'>
                        <img className='range2p' src={range2} alt="range2" />
                     </div>
                </div>
            </div>

            <div style={{backgroundColor: 'darkblue', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', marginTop: '0.2%'}}>
                <h1>SPECIFICATIONS</h1>
                    <div className='specs'>
                    <div className='specstext'>
                        <h4 style={{textDecoration: 'underline'}}><b>Cabinets 6U ~ 42U Specification</b></h4>
                        <ul style={{textAlign: 'left', marginLeft: '2%'}}>
                            <li><h5>Different IP ratings available</h5></li>
                            <li><h5>Grounding and earthing kits</h5></li>
                            <li><h5>Thick and robust construction</h5></li>
                            <li><h5>Easy and quick assembly</h5></li>
                            <li><h5>Rotating front door, opens at 180 degree</h5></li>
                            <li><h5>All doors available in different configurations</h5></li>
                            <li><h5>Easy removeable side doors with latches (optional locks on side doors)</h5></li>
                            <li><h5>Vented cabinet for better airflow and vented roof ready for fan tray/unit</h5></li>
                            <li><h5>Various colours and configurations available</h5></li>
                            <li><h5>Various door designs available (mesh or glass)</h5></li>
                            <li><h5>Pre-assembled and welded</h5></li>
                        </ul>
                        </div>
                    <div className='specsp'>
                        <img className='specsimg' src={specs} alt="specs" />
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: 'darkblue', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', marginTop: '0.2%'}}>
                <h1>CABLE TRAYS</h1>
                <div className='cabletrays'>
                    <div className='cabletraysp'>
                        <img className='ctimg' src={ct} alt="ct" />
                    </div>
                    <div className='cabletraystext'>
                        <h5 className='basich5'>We manufacture premium quality galvanized iron (GI) and power-coated cable trays and ladders, designed for effective cable management. Our range includes essential accessories such as bends and hangers, providing a complete solution for efficient cable routing. Engineered for durability and performance, our products are ideal for both commercial and industrial applications.</h5>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: 'darkblue', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', marginTop: '0.2%'}}>
                <h1>Battery Cabinet</h1>
                <div className='batterycabinet'>
                    <div className='batterycabinettext'>
                        <h5 className='basich5'>We also manufacture premium quality power-coated stands and cabinets specifically designed for UPS battery storage. Built for durability and stability, our stands and cabinets provide a safe and organized solution for housing UPS batteries in both commercial and industrial environments. With high-quality finishes and robust construction, they ensure long-lasting performance and efficient space utilization</h5>
                    </div>
                    <div className='batterycabinetp'>
                        <img className='bcimg' src={bc} alt="bc" />
                    </div>
                </div>
            </div>
        <Footer/>
    </div>
  )
}

export default Products